import React, { ReactElement } from "react";
import { GetStaticProps, InferGetStaticPropsType } from "next";

import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Meta from "../components/layout/Meta";
import CheckoutLayout from "../components/layout/CheckoutLayout";
import { env } from "@precomposer/env";
import { getClient } from "@precomposer/data";
import { StoryblokComponent, useStoryblokState } from "@storyblok/react";
import { Breadcrumb, Heading } from "@precomposer/ui";
import { StoryblokSlug } from "../lib/storyblokSlug";
import { fetchStoryblokStory } from "../lib/storyblok.util";

export default function NotFountPage(
  content: InferGetStaticPropsType<typeof getStaticProps>
) {
  const { t } = useTranslation(["common"]);
  const editableStory = useStoryblokState(content.story);

  return (
    <>
      <Meta title={t("common:404.title")} nofollow={true} noindex={true} />
      <>
        <Breadcrumb>
          <Breadcrumb.Item href={"/"}>{t("common:home.title")}</Breadcrumb.Item>
          <Breadcrumb.Item>{t("common:404.title")}</Breadcrumb.Item>
        </Breadcrumb>
        {editableStory ? (
          <StoryblokComponent blok={editableStory.content} />
        ) : (
          <>
            <Heading className={"mb-6"}>{t("common:404.headline")}</Heading>
            <p>{t("common:404.text")}</p>
          </>
        )}
      </>
    </>
  );
}

NotFountPage.getLayout = function getLayout(page: ReactElement) {
  return <CheckoutLayout>{page}</CheckoutLayout>;
};

export const getStaticProps: GetStaticProps<any> = async ({ locale }) => {
  const storyblokStory = await fetchStoryblokStory(
    `${env("CMS_BASE_PATH")}/${StoryblokSlug.NOT_FOUND_PAGE}`.replace(
      /^\/+|\/+$/g,
      ""
    ),
    locale,
    getClient()
  );

  const story = storyblokStory?.story || null;

  if (!story) {
    console.warn(`Storyblok story for not found page does not exist`);
  }

  return {
    props: {
      story: story ? { ...story, content: story.content || null } : null,
      ...(await serverSideTranslations(locale, [
        "common",
        "account",
        "search",
      ])),
    },
    revalidate: 60,
  };
};
