import React, { FC, ReactNode } from "react";
import Header from "./Header";
import { Navbar } from "@precomposer/ui";
import Logo from "./Logo";
import Footer from "./Footer";

type Props = {
  children: ReactNode;
};

const CheckoutLayout: FC<Props> = ({ children }) => {
  return (
    <div className="flex h-screen flex-col justify-between lg:container lg:mx-auto">
      <Header>
        <Navbar>
          <Navbar.Brand href={"/"}>
            <Logo />
          </Navbar.Brand>
        </Navbar>
      </Header>
      <main className={"flex-1 px-3 lg:px-0"}>{children}</main>
      <Footer />
    </div>
  );
};

export default CheckoutLayout;
